<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import structuredData from '@core/mixins/structuredData';
import * as settings from '@model/const/events';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { assertHttpErrors } from '@utils/api-response';
import eventUtils from '@utils/events.js';
import { getOgImage } from '@utils/html-meta';
import EventsHomeComponent from './component.vue';

export default Vue.extend({
    name: 'EventsHomeContainer',

    mixins: [structuredData],

    data() {
        return {
            interfaceLocaleData: null,
        };
    },

    async serverPrefetch(): Promise<void> {
        const languageQuery = new ProductAPIQueryBuilder('languages')
            .setEntityPath('/api/events/languages/')
            .setPaginate(1, settings.LANGUAGES_COUNT)
            .toObject();

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 'events-locales', locale: LOCALE_DEFAULT }),
            this.$store.dispatch('events/getEntity', { request: languageQuery }),
            this.$store.dispatch('countries/getCountries'),
        ]);

        const categoriesQuery = new ProductAPIQueryBuilder('categories')
            .setEntityPath('/api/events/categories/')
            .setPaginate(1, settings.CATEGORIES_COUNT)
            .toObject();

        const audiencesQuery = new ProductAPIQueryBuilder('audiences')
            .setEntityPath('/api/events/audiences/')
            .setPaginate(1, settings.AUDIENCES_COUNT)
            .toObject();

        const unlocalizedPromises = [
            this.$store.dispatch('events/getEntity', { request: categoriesQuery }),
            this.$store.dispatch('events/getEntity', { request: audiencesQuery }),
        ];

        const countryId = parseInt(this.$ssrContext.req.cookies.events_country, 10);
        let localizedPromises = [];

        /* All country-specific requests will be done server-side if country code is known
         * Otherwise these requests will be done in browser when @onLocationSet triggers
         * after country code is received using Geo API
         */
        if (!Number.isNaN(countryId)) {
            this.interfaceLocaleData = this.getInterfaceLocaleData(countryId);

            let language;
            if (this.interfaceLocaleData?.data?.defaultLanguage) {
                const languages = this.$store.state.events.languages?.items || [];
                language = languages.find((x) => x.name === this.interfaceLocaleData.data.defaultLanguage)?.id;
            }

            localizedPromises = [
                this.$store.dispatch('events/setCountry', { countryId, isConfirmed: true }),
                this.loadBanners({ countryId }),
                this.loadInPersonEvents({ countryId, language }),
                this.loadWebinarsAndVirtualEvents({ countryId, webinarsCategoryId: 'all', language }),
                this.loadArchiveEvents({ countryId, archiveCategoryId: 'all', language }),
            ];
        }

        const seoLocale = 'en-us';
        const seoPromises = [
            this.$store.dispatch('seo/getSyncedData', { key: 'routes-config', locale: seoLocale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-website', locale: seoLocale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-organization', locale: seoLocale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-contact-point', locale: seoLocale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-offer', locale: seoLocale }),
            this.$store.dispatch('seo/getSyncedData', { key: 'schema-postal-address', locale: seoLocale }),
        ];

        const interfaceLocale = this.interfaceLocaleData?.locale || LOCALE_DEFAULT;
        await Promise.all([
            ...unlocalizedPromises,
            ...localizedPromises,
            this.$store.dispatch('slices/getSyncedData', { slice: 'events-interface-translations', locale: interfaceLocale }),
        ]);

        assertHttpErrors([
            { entity: this.$store.state.countries },
            { entity: this.$store.state.events.categories },
            { entity: this.$store.state.events.audiences },
            { entity: this.$store.state.events.banners },
            { entity: this.$store.state.events.inPersonEvents },
            { entity: this.$store.state.events.virtualEvents },
            { entity: this.$store.state.events.pastEvents },
        ]);

        await Promise.all(seoPromises);

        // Building page meta
        this.$ssrContext.res.meta = await this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
        ...mapGetters('seo', ['getRoutesConfig', 'getStaticConfigs']),
    },

    methods: {
        async getMeta(): any {
            const uiStrings: any = this.$store.state.slices.items['events-interface-translations'] || {};
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const ogImage = getOgImage(`@${uiStrings.ogImage}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = 'Acronis Events';
            const description = uiStrings.homePageLead;

            const ldJsonSchema = await this.getStructuredDataMarkup({
                siteID: this.$config.siteID,
                domain: this.$config.domain,
                schemasConfig: this.getStaticConfigs,
                routesConfig: this.getRoutesConfig,
            });

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                ldJsonSchema,
                // htmlAttrs: {
                //     dir: getTextDirection(locale),
                //     lang: locale.split('-')[0],
                // },
            };
        },

        loadBanners(searchQuery: any): void {
            const now = new Date().toISOString();
            const request = new ProductAPIQueryBuilder('banners')
                .setEntityPath('/api/events/banners/')
                .setCustomParam('has', { countries: [searchQuery.countryId] })
                .addMatchesAll('is_featured', '=', '1')
                .addMatchesAll('active_from', '<=', now)
                .addMatchesAll('active_until', '>', now)
                .setPaginate(1, settings.BANNERS_COUNT)
                .toObject();

            return this.$store.dispatch('events/getEntity', { request });
        },

        loadInPersonEvents(searchQuery: any) {
            const queryBuilder = new ProductAPIQueryBuilder('inPersonEvents')
                .setEntityPath('/api/events/events/')
                .addMatchesAll('is_in_person', '=', '1')
                .addMatchesAll('is_for_partners', '<>', '1')
                .addMatchesAll('language_id', '=', searchQuery.language)
                .setCustomParam('has', { countries: [searchQuery.countryId] })
                .setCustomParam('has-occurrences', { from: new Date().toISOString() })
                .setCustomParam('process-macros', '1')
                .addSort('is_featured', 'desc')
                .addSort('dynamic_next_occurrence', 'asc')
                .setPaginate(1, settings.IN_PERSON_EVENTS_COUNT);

            const request = queryBuilder.toObject();

            return this.$store.dispatch('events/getEntity', { request });
        },

        loadWebinarsAndVirtualEvents(searchQuery: any) {
            const categoryId = searchQuery.webinarsCategoryId !== 'all' ? searchQuery.webinarsCategoryId : null;
            const hasCriteria: Record<string, any> = {
                countries: [searchQuery.countryId],
            };
            if (categoryId) hasCriteria.categories = [categoryId];

            const request = new ProductAPIQueryBuilder('virtualEvents')
                .setEntityPath('/api/events/events/')
                .setCustomParam('has-occurrences', { from: new Date().toISOString() })
                .addMatchesAll('is_virtual', '=', '1')
                .addMatchesAll('is_for_partners', '<>', '1')
                .addMatchesAll('language_id', '=', searchQuery.language)
                .setCustomParam('has', hasCriteria)
                .setCustomParam('process-macros', '1')
                .addSort('is_featured', 'desc')
                .addSort('dynamic_next_occurrence', 'asc')
                .setPaginate(1, settings.WEBINARS_AND_VIRTUAL_COUNT)
                .toObject();

            return this.$store.dispatch('events/getEntity', { request });
        },

        loadArchiveEvents(searchQuery: any) {
            const categoryId = searchQuery.archiveCategoryId !== 'all' ? searchQuery.archiveCategoryId : null;
            const hasCriteria: Record<string, any> = {
                countries: [searchQuery.countryId],
            };
            if (categoryId) hasCriteria.categories = [categoryId];

            const request = new ProductAPIQueryBuilder('pastEvents')
                .setEntityPath('/api/events/events/')
                .addMatchesAny('is_past', '=', '1')
                .addMatchesAny('is_on_demand', '=', '1')
                .addMatchesAll('is_for_partners', '<>', '1')
                .addMatchesAll('language_id', '=', searchQuery.language)
                .setCustomParam('has-occurrences', { to: new Date().toISOString() })
                .setCustomParam('has', hasCriteria)
                .setCustomParam('process-macros', '1')
                .addSort('is_featured', 'desc')
                .addSort('dynamic_last_occurrence', 'desc')
                .setPaginate(1, settings.ARCHIVE_EVENTS_COUNT)
                .toObject();

            return this.$store.dispatch('events/getEntity', { request });
        },

        getInterfaceLocaleData(countryId: number) {
            const countries = this.$store.state.countries.items || [];
            const eventsLocales = this.$store.state.slices.items['events-locales'] || {};
            const selectedCountry = countries.find((country) => country.id === countryId);

            if (selectedCountry) {
                const [locale, localeData] = Object.entries(eventsLocales)
                    .find(([_, data]: any) => data.isoCodes.includes(selectedCountry.iso_code)) || [null, null];
                return { locale, data: localeData };
            }
            return null;
        },
    },

    render(h) {
        const getInterfaceLocaleData = () => {
            if (this.interfaceLocaleData) return this.interfaceLocaleData;
            const countryId = eventUtils.getCountryCookie();
            return this.getInterfaceLocaleData(countryId);
        };

        const props = {
            interfaceLocaleData: getInterfaceLocaleData(),
            dispatchLoadBanners: this.loadBanners,
            dispatchLoadInPersonEvents: this.loadInPersonEvents,
            dispatchLoadWebinars: this.loadWebinarsAndVirtualEvents,
            dispatchLoadArchiveEvents: this.loadArchiveEvents,
        };
        return h(EventsHomeComponent, { props });
    },
});
</script>
