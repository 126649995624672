<script lang="ts">
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { ProductAPIQueryBuilder } from '@api/builders/product';
import * as settings from '@model/const/events';
import { LOCALE_DEFAULT } from '@model/const/locales';
import { assertHttpErrors } from '@utils/api-response';
import eventUtils from '@utils/events.js';
import { getOgImage } from '@utils/html-meta';
import EventsArchiveComponent from './component.vue';

export default Vue.extend({
    name: 'EventsArchiveContainer',

    data() {
        return {
            interfaceLocaleData: null,
        };
    },

    async serverPrefetch(): Promise<void> {
        const languageQuery = new ProductAPIQueryBuilder('languages')
            .setEntityPath('/api/events/languages/')
            .setPaginate(1, settings.LANGUAGES_COUNT)
            .toObject();

        await Promise.all([
            this.$store.dispatch('slices/getSyncedData', { slice: 'events-locales', locale: LOCALE_DEFAULT }),
            this.$store.dispatch('countries/getCountries'),
            this.$store.dispatch('events/getEntity', { request: languageQuery }),
        ]);

        const categoriesQuery = new ProductAPIQueryBuilder('categories')
            .setEntityPath('/api/events/categories/')
            .setPaginate(1, settings.CATEGORIES_COUNT)
            .toObject();

        const audiencesQuery = new ProductAPIQueryBuilder('audiences')
            .setEntityPath('/api/events/audiences/')
            .setPaginate(1, settings.AUDIENCES_COUNT)
            .toObject();

        const unlocalizedPromises = [
            this.$store.dispatch('events/getEntity', { request: categoriesQuery }),
            this.$store.dispatch('events/getEntity', { request: audiencesQuery }),
        ];

        const countryId = parseInt(this.$ssrContext.req.cookies.events_country, 10);
        let localizedPromises = [];

        if (!Number.isNaN(countryId)) {
            this.interfaceLocaleData = this.getInterfaceLocaleData(countryId);

            let language;
            if (this.interfaceLocaleData?.data?.defaultLanguage) {
                const languages = this.$store.state.events.languages?.items || [];
                language = languages.find((x) => x.name === this.interfaceLocaleData.data.defaultLanguage)?.id;
            }
            const searchQuery = { ...this.$route.query, countryId, language };

            localizedPromises = [
                this.loadProducts(this.interfaceLocaleData?.locale),
                this.$store.dispatch('events/setCountry', { countryId, isConfirmed: true }),
                this.getSearchResults(searchQuery),
            ];
        }

        const interfaceLocale = this.interfaceLocaleData?.locale || LOCALE_DEFAULT;
        await Promise.all([
            ...unlocalizedPromises,
            ...localizedPromises,
            this.$store.dispatch('slices/getSyncedData', { slice: 'events-interface-translations', locale: interfaceLocale }),
        ]);

        assertHttpErrors([
            { entity: this.$store.state.countries },
            { entity: this.$store.state.events.categories },
            { entity: this.$store.state.events.audiences },
            { entity: this.$store.state.events.languages },
            { entity: this.$store.state.events.searchResults },
        ]);

        // Building page meta
        this.$ssrContext.res.meta = this.getMeta();
    },

    computed: {
        ...mapGetters('config', ['$config']),
    },

    methods: {
        getMeta(): any {
            const uiStrings: any = this.$store.state.slices.items['events-interface-translations'] || {};
            const canonical = `https://${this.$config.domain}${this.$route.path}`;
            const ogImage = getOgImage(`@${uiStrings.ogImage}`, this.$config.env.HEAD_SITE_MAIN_PUBLIC_BASE_URL_STORAGE);
            const title = uiStrings.archivePageTitle;
            const description = uiStrings.homePageLead;

            return {
                title,
                head: [
                    { tag: 'meta', name: 'title', content: title },
                    { tag: 'meta', name: 'description', content: description },
                    { tag: 'meta', property: 'og:title', content: title },
                    { tag: 'meta', property: 'og:description', content: description },
                    { tag: 'meta', property: 'og:image', content: ogImage },
                    { tag: 'meta', property: 'og:url', content: canonical },
                    { tag: 'meta', name: 'twitter:title', content: title },
                    { tag: 'meta', name: 'twitter:description', content: description },
                    { tag: 'meta', name: 'twitter:image', content: ogImage },
                    { tag: 'meta', name: 'twitter:url', content: canonical },
                    { tag: 'link', rel: 'image_src', href: ogImage },
                    { tag: 'link', rel: 'canonical', href: canonical },
                ],
                // htmlAttrs: {
                //     dir: getTextDirection(locale),
                //     lang: locale.split('-')[0],
                // },
            };
        },

        loadProducts(interfaceLocale: string) {
            return this.$store.dispatch('products/getProducts', interfaceLocale);
        },

        getSearchResults(searchQuery: any) {
            const hasCriteria: Record<string, any> = {
                countries: [searchQuery.countryId],
            };
            if (searchQuery.audience) hasCriteria.audiences = [searchQuery.audience];
            if (searchQuery.product) hasCriteria.products = [searchQuery.product];
            if (searchQuery.category) hasCriteria.categories = [searchQuery.category];

            let occurrenceCriteria: Record<string, string>;
            // Has PAST occurrence
            occurrenceCriteria = { to: new Date().toISOString() };
            // Period of ANY occurrence
            if (searchQuery.date?.length > 0) {
                // plus one day to end range to cover the last day
                const lastDay = new Date(searchQuery.date[1]);
                lastDay.setDate(lastDay.getDate() + 1);

                occurrenceCriteria = {
                    from: new Date(searchQuery.date[0]).toISOString(),
                    to: lastDay.toISOString(),
                };
            }

            const request = new ProductAPIQueryBuilder('searchResults')
                .setEntityPath('/api/events/events/')
                .addMatchesAny('is_past', '=', '1')
                .addMatchesAny('is_on_demand', '=', '1')
                .addMatchesAll('is_for_partners', '<>', '1')
                .addMatchesAll('language_id', '=', searchQuery.language)
                .setCustomParam('has', hasCriteria)
                .setCustomParam('has-occurrences', occurrenceCriteria)
                .setCustomParam('process-macros', '1')
                .addSort('is_featured', 'desc')
                .addSort('dynamic_last_occurrence', 'desc')
                .setPaginate(searchQuery.currentPage, searchQuery.cardsCountToShow)
                .toObject();

            return this.$store.dispatch('events/getEntity', { request });
        },

        getInterfaceLocaleData(countryId: number) {
            const countries = this.$store.state.countries.items || [];
            const eventsLocales = this.$store.state.slices.items['events-locales'] || {};
            const selectedCountry = countries.find((country) => country.id === countryId);

            if (selectedCountry) {
                const [locale, localeData] = Object.entries(eventsLocales)
                    .find(([_, data]: any) => data.isoCodes.includes(selectedCountry.iso_code)) || [null, null];
                return { locale, data: localeData };
            }
            return null;
        },
    },

    render(h) {
        const getInterfaceLocaleData = () => {
            if (this.interfaceLocaleData) return this.interfaceLocaleData;
            const countryId = eventUtils.getCountryCookie();
            return this.getInterfaceLocaleData(countryId);
        };

        const props = {
            interfaceLocaleData: getInterfaceLocaleData(),
            dispatchSearch: async (searchQuery: any) => { await this.getSearchResults(searchQuery); },
            dispatchLoadProducts: this.loadProducts,
        };
        return h(EventsArchiveComponent, { props });
    },
});
</script>
